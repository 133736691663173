import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as pollingActions from "modules/polling/actions";
import * as monitoringActions from "modules/monitoring/actions";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_ROLES, ROUTES } from "../../constants";
import * as s from "./styles";
import { Loader } from "components/common/Loader";
import {
  isResourceUtilizationDataLoadingSelector,
  resourceUtilizationDataSelector
} from "modules/monitoring/selectors";
import { PieChartMetric } from "components/common/PieChartMetric";

const POLL_ID_PREFIX = "MONITORING";
const POLL_IDS = {
  CPU: "CPU"
};

export const MonitoringCPU: FC = () => {
  const dispatch = useDispatch();
  const adminRole = useSelector(adminRoleSelector);

  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );
  const monitoringData = useSelector(resourceUtilizationDataSelector);
  const isMonitoringDataLoading = useSelector(
    isResourceUtilizationDataLoadingSelector
  );

  const title = `CPU Monitoring`;

  const breadcrumbs: Breadcrumb[] = [{ text: "CPU", url: ROUTES.CPU }];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.CPU}`,
        action: monitoringActions.getResourceUtilizationData.started({
          region: "all"
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(monitoringActions.clear());
  });

  const renderMetric = (
    title: string,
    usedValue: number,
    freeValue: number,
    maxValue: number,
    unit: string = "",
    simpleMetrics
  ) => {
    return (
      <PieChartMetric
        title={title}
        usedValue={usedValue}
        freeValue={freeValue}
        maxValue={maxValue}
        unit={unit}
        simpleMetrics={simpleMetrics}
      />
    );
  };

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>

      <s.MetricsContainer variant={"outlined"}>
        {!monitoringData ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <s.MetricRowContainer>
              {monitoringData.map((data, index) => (
                <s.MetricContainer key={index}>
                  {renderMetric(
                    data.region,
                    data.statistics.vcpus_used,
                    data.statistics.vcpus - data.statistics.vcpus_used,
                    data.statistics.vcpus,
                    "vCPUs",
                    [
                      {
                        label: "Physical Cores",
                        value: data.statistics.cpus,
                        unit: ""
                      },
                      {
                        label: "vCPU",
                        value: data.statistics.vcpus,
                        unit: ""
                      },
                      {
                        label: "Overallocation ratio",
                        value: data.statistics.vcpu_allocation_ratio,
                        unit: ""
                      }
                    ]
                  )}
                </s.MetricContainer>
              ))}
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer>
    </>
  );
};
