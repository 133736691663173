import actionCreatorFactory from "typescript-fsa";
import {
  GetPublicIPsParams,
  GetPublicIPsResponse,
  UpdateCachePublicIPsResponse
} from "./types";

const actionCreator = actionCreatorFactory("NETWORKING");

export const getPublicIPs = actionCreator.async<
  GetPublicIPsParams,
  GetPublicIPsResponse,
  unknown
>("GET_PUBLIC_IPS");

export const updateCachePublicIPs = actionCreator.async<
  void,
  UpdateCachePublicIPsResponse,
  unknown
>("UPDATE_CACHE_PUBLIC_IPS");

export const clear = actionCreator<void>("CLEAR");
