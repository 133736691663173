import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Instance } from "./types";

export type Reducer = {
  gpuInstances: Instance[] | null;
  areGpuInstancesLoading: boolean;
};

const initialState: Reducer = {
  gpuInstances: null,
  areGpuInstancesLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getGpuInstances.started,
    (state): Reducer => ({
      ...state,
      areGpuInstancesLoading: true
    })
  )
  .case(
    actions.getGpuInstances.done,
    (state, payload): Reducer => ({
      ...state,
      gpuInstances: payload.result,
      areGpuInstancesLoading: false
    })
  )
  .case(
    actions.getGpuInstances.failed,
    (state): Reducer => ({
      ...state,
      areGpuInstancesLoading: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
