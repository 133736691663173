import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import { TableColumn } from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as networkingActions from "modules/networking/actions";
import {
  arePublicIPsLoadingSelector,
  isPublicIPsCacheUpdatingSelector,
  publicIPsCashDataSelector,
  publicIPsSelector,
  publicIPsTotalSelector
} from "modules/networking/selectors";
import { PublicIP } from "modules/networking/types";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { useDebounce } from "usehooks-ts";
import { downloadFile } from "utils/downloadFile";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { array } from "yup";
import { appConfig } from "../../appConfig";
import { API_PATH } from "../../axios";
import {
  ADMIN_ROLES,
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_PAGINATION_LIMIT,
  MAX_PAGINATION_LIMIT,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "IPs";

const POLL_IDS = {
  ips: "IPs"
};

const title = "Public IPs";

const breadcrumbs: Breadcrumb[] = [
  { text: "PublicIPs", url: ROUTES.PUBLIC_IPS }
];

const tableColumns: TableColumn<PublicIP>[] = [
  { key: "ip", label: "Public IP" },
  { key: "type", label: "Type" },
  { key: "project_id", label: "Project ID" },
  { key: "project_name", label: "Project Name" },
  { key: "user_name", label: "Project Owner" },
  { key: "email", label: "Email" }
];

export const Public_IPs: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const isOperatorRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OPERATOR)
  );

  const publicIPs = useSelector(publicIPsSelector);
  const publicIPsTotal = useSelector(publicIPsTotalSelector);
  const publicIPsCashData = useSelector(publicIPsCashDataSelector);
  const prevPublicIPsCashData = usePrevious(publicIPsCashData);
  const [isRefreshButtonDisabled, setIsRefreshButtonDisabled] = useState(false);

  const arePublicIPsLoading = useSelector(arePublicIPsLoadingSelector);
  const isPublicIPsCacheUpdating = useSelector(
    isPublicIPsCacheUpdatingSelector
  );

  const queryParams = new URLSearchParams(location.search);
  const pageParam = Number(queryParams.get("page"));
  const [page, setPage] = useState<number>(
    Number.isInteger(pageParam) && pageParam > 0 ? pageParam - 1 : 0
  );
  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );
  const itemsParam = Number(queryParams.get("items"));
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number.isInteger(itemsParam) &&
      itemsParam > 0 &&
      itemsParam <= MAX_PAGINATION_LIMIT
      ? itemsParam
      : DEFAULT_PAGINATION_LIMIT
  );
  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
    },
    []
  );

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);
  const prevDebounceSearch = usePrevious(debouncedSearch);
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // setPage(0);
  }, []);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.GENERATE_CSV_FILE, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const [showOnly, setShowOnly] = useState<string>(
    tableRegionsOptions[0].value
  );
  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
    setPage(0);
  }, []);

  const handleConfirmGenerateButtonClick = useCallback(
    (data: { region: SelectOption[] }) => {
      const regionsString = data.region.map((item) => item.value).join(",");
      downloadFile(
        `${API_PATH}gotham-governor/method/admin/ips/csv?regions=${regionsString}`
      );
      handleCloseDialog();
      dispatch(
        notificationsActions.showNotification({
          title: "CSV file is generating. Download will start soon.",
          type: NOTIFICATION_TYPES.INFO
        })
      );
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmUpdateCacheData = useCallback(() => {
    dispatch(networkingActions.updateCachePublicIPs.started());
    setIsRefreshButtonDisabled(true);
  }, [dispatch]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.GENERATE_CSV_FILE]: {
      onConfirm: handleConfirmGenerateButtonClick,
      title: "Generate CSV file",
      confirmButtonLabel: "Generate",
      fields: [
        {
          name: "region",
          type: FIELD_TYPES.MULTISELECT,
          label: "Region",
          options: tableRegionsOptions,
          defaultValue: tableRegionsOptions,
          rules: array(selectOptionSchema)
        }
      ]
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`,
        action: networkingActions.getPublicIPs.started({
          region: showOnly,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
    // dispatch(
    //   networkingActions.getPublicIPs.started({
    //     region: showOnly,
    //     offset: page * rowsPerPage,
    //     limit: rowsPerPage,
    //     ...(debouncedSearch ? { search: debouncedSearch } : {})
    //   })
    // );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(networkingActions.clear());
  });

  useEffect(() => {
    if (isOperatorRoleAllowed) {
      let offset = page * rowsPerPage;
      if (debouncedSearch !== prevDebounceSearch) {
        setPage(0);
        offset = 0;
      }
      history({
        search: generateSearchString({
          page: String(page + 1),
          items: String(rowsPerPage),
          region: String(showOnly),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      });
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`
        })
      );
      // dispatch(
      //   networkingActions.getPublicIPs.started({
      //     region: showOnly,
      //     offset: offset,
      //     limit: rowsPerPage,
      //     ...(debouncedSearch ? { search: debouncedSearch } : {})
      //   })
      // );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.ips}`,
          action: networkingActions.getPublicIPs.started({
            region: showOnly,
            offset: offset,
            limit: rowsPerPage,
            ...(debouncedSearch ? { search: debouncedSearch } : {})
          })
        })
      );
    }
  }, [
    prevDebounceSearch,
    debouncedSearch,
    dispatch,
    isOperatorRoleAllowed,
    page,
    rowsPerPage,
    showOnly,
    history
  ]);

  useEffect(() => {
    if (isNumber(publicIPsTotal) && rowsPerPage * page > publicIPsTotal) {
      setPage(0);
    }
  }, [publicIPsTotal, rowsPerPage, page]);

  useEffect(() => {
    if (publicIPsCashData !== prevPublicIPsCashData) {
      setIsRefreshButtonDisabled(false);
    }
  }, [prevPublicIPsCashData, publicIPsCashData]);

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
        <s.ActionsContainer>
          {publicIPsCashData && (
            <Tooltip title={"Report updated at:"} placement={"top"} arrow>
              <span>
                <s.Description>Report date: {publicIPsCashData}</s.Description>
              </span>
            </Tooltip>
          )}

          <Tooltip title={"Refresh report"} placement={"top"} arrow>
            <span>
              <IconButton
                onClick={handleConfirmUpdateCacheData}
                color={"inherit"}
                disabled={isRefreshButtonDisabled || !publicIPs}
              >
                <RefreshIcon />
              </IconButton>
            </span>
          </Tooltip>
        </s.ActionsContainer>
      </s.SummaryContainer>
      <Table
        isSearchEnabled={true}
        searchString={search}
        searchLabel={"Search by IP"}
        onChangeSearch={handleChangeSearch}
        isSortingEnabled={true}
        isPaginationEnabled={true}
        page={page}
        count={publicIPsTotal || 0}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rows={publicIPs || []}
        columns={tableColumns}
        isLoading={!publicIPs}
        // isLoading={arePublicIPsLoading || isPublicIPsCacheUpdating}
        toolbarItems={
          <>
            <span>
              <Button
                onClick={() => handleDialogOpen(DIALOG_TYPES.GENERATE_CSV_FILE)}
                variant={"contained"}
                style={{ width: "200px" }}
                disabled={!publicIPs}
              >
                Export CSV file
              </Button>
            </span>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              onChange={handleChangeFilter}
            />
          </>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
