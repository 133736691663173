import { ChangeEvent, useCallback, useState } from "react";
import * as s from "./styles";
import {
  TableCell,
  TableRow,
  IconButton,
  Collapse,
  Box,
  TableBody,
  TableHead,
  Paper,
  TableFooter,
  Tooltip
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React from "react";
import { Loader } from "../Loader";
import { CollapsibleData, TableCollapsibleProps } from "./types";

function getCollapsibleData<T>(
  row: T,
  key: keyof any
): CollapsibleData[] | undefined {
  const value = row[key];
  return Array.isArray(value) ? (value as CollapsibleData[]) : undefined;
}

export const TableCollapsible = <T,>({
  rows,
  columns,
  collapsibleDataColumns,
  collapsibleDataKey,
  toolbarItems,
  totalRow,
  isLoading,
  isSearchEnabled,
  searchString,
  onChangeSearch,
  itemWithIcon
}: TableCollapsibleProps<T>) => {
  const [open, setOpen] = useState<number | null>(null);

  const toggleOpen = (index: number) => {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  };

  const [search, setSearch] = useState("");
  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChangeSearch) {
        onChangeSearch(e);
      } else {
        setSearch(e.target.value);
      }
    },
    [onChangeSearch]
  );
  const filteredRows = search
    ? rows.filter((row) =>
        columns.some(
          (column) =>
            String(row[column.key])
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
        )
      )
    : rows;

  return (
    <s.Container>
      <s.Toolbar>
        {toolbarItems}
        {isSearchEnabled && (
          <s.SearchTextField
            value={onChangeSearch ? searchString : search}
            label={"Search"}
            inputProps={{
              title: "Search"
            }}
            onChange={handleSearchChange}
            size={"small"}
          />
        )}
      </s.Toolbar>
      <Paper variant="outlined">
        <s.CustomTable stickyHeader data-testid={"table-container"}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              {columns.map((column) => (
                <TableCell
                  key={column.key as string}
                  style={
                    column.width || column.isCentered
                      ? {
                          width: column.width ? column.width : "auto"
                          // textAlign: column.isCentered ? "center" : "left"
                        }
                      : {}
                  }
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell padding="checkbox">
                          {collapsibleDataKey && collapsibleDataColumns && (
                            <IconButton
                              size="small"
                              onClick={() => toggleOpen(index)}
                              disabled={
                                getCollapsibleData(row, collapsibleDataKey)
                                  ?.length === 0
                              }
                            >
                              {open === index ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          )}
                        </TableCell>

                        {columns.map((column) => (
                          <TableCell
                            key={String(column.key)}
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                          >
                            {itemWithIcon &&
                            itemWithIcon.column === column.key ? (
                              <s.IconContainer
                              // centered={Boolean(!itemWithIcon.withText)}
                              >
                                <Tooltip
                                  title={itemWithIcon.tooltipText(row)}
                                  placement="top"
                                  arrow
                                >
                                  <span>
                                    {itemWithIcon.getIcon(
                                      String(
                                        row[column.key as keyof typeof row]
                                      )
                                    )}
                                  </span>
                                </Tooltip>
                                {itemWithIcon.withText && (
                                  <s.TableCellTextWithMargin variant="body2">
                                    {String(
                                      row[column.key as keyof typeof row]
                                    )}
                                  </s.TableCellTextWithMargin>
                                )}
                              </s.IconContainer>
                            ) : (
                              row[column.key as keyof typeof row]
                            )}

                            {/* {row[column.key as keyof typeof row]} */}
                          </TableCell>
                        ))}
                      </TableRow>
                      {collapsibleDataKey &&
                        collapsibleDataColumns &&
                        getCollapsibleData(row, collapsibleDataKey) &&
                        open === index && (
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={columns.length + 1}
                            >
                              <Collapse
                                in={open === index}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  <s.CollapsedTable size="small">
                                    <TableHead>
                                      <TableRow>
                                        {collapsibleDataColumns.map((col) => (
                                          <TableCell
                                            key={col.key}
                                            style={{ textAlign: "center" }}
                                          >
                                            {col.label}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {getCollapsibleData(
                                        row,
                                        collapsibleDataKey
                                      )?.map((item: CollapsibleData, i) => (
                                        <TableRow key={i}>
                                          {collapsibleDataColumns.map((col) => (
                                            <TableCell
                                              key={col.key}
                                              style={{ textAlign: "center" }}
                                            >
                                              {item[col.key]}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </s.CollapsedTable>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      // colSpan={columns.length + (actions ? 1 : 0)}
                      colSpan={columns.length + 1}
                      data-testid={"table-cell-no-data"}
                    >
                      <s.NoDataText data-testid={"no-data-text"}>
                        No data
                      </s.NoDataText>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {totalRow && (
                <TableFooter>
                  <TableRow>
                    <TableCell padding="checkbox" />
                    {columns.map((column) => (
                      <TableCell key={String(column.key)}>
                        <s.TableTotalCellText>
                          {String(totalRow[column.key as keyof T])}
                        </s.TableTotalCellText>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </s.CustomTable>
        {isLoading && (
          <s.LoaderContainer>
            <Loader text={"Loading data..."} />
          </s.LoaderContainer>
        )}
      </Paper>
    </s.Container>
  );
};
