import {
  SelectChangeEvent,
  Select,
  Typography,
  Autocomplete,
  TextField
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { DatePicker } from "@mui/x-date-pickers";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import * as billingActions from "modules/billing/actions";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import startOfMonth from "date-fns/startOfMonth";
import sub from "date-fns/sub";
import { LineChart } from "@mui/x-charts/LineChart";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  DATE_FORMATS,
  DEFAULT_CURRENCY,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { formatDate } from "utils/formatDate";
import { DATE_TYPES } from "components/BillingOrganization/types";
import {
  dailyBillingDataSelector,
  formattedMonthCostsSelector,
  monthBillingDataSelector,
  isDailyBillingDataLoadingSelector,
  isMonthBillingDataLoadingSelector
} from "modules/billing/selectors";
import { Loader } from "components/common/Loader";
import { DailyCostsCurrencyFormatted } from "modules/billing/types";
import {
  isFirstDayOfMonth,
  addMonths,
  subDays,
  parse,
  endOfMonth,
  isSameMonth,
  max
} from "date-fns";
import { usePrevious } from "hooks/usePrevious";

const title = "Billing Charts";

const breadcrumbs: Breadcrumb[] = [
  { text: "Billing Charts", url: ROUTES.BILLING_CHARTS }
];

export const Billing_Charts: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const adminRole = useSelector(adminRoleSelector);

  const isBillingRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.BILLING)
  );

  // daily chart
  const dailyCosts = useSelector(dailyBillingDataSelector);
  const isDailyBillingDataLoading = useSelector(
    isDailyBillingDataLoadingSelector
  );

  // const currentDate: Date = new Date(2024, 6, 1); // 1.07.2024
  const currentDate = useMemo(() => new Date(), []);
  const minDate = sub(currentDate, { days: 400 });

  const getStartDate = (date: Date) => {
    if (isFirstDayOfMonth(date)) {
      return startOfMonth(addMonths(date, -1));
    }
    return startOfMonth(date);
  };

  const [startDate, setStartDate] = useState<string | null>(
    formatDate(getStartDate(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    formatDate(subDays(currentDate, 1), DATE_FORMATS.ISO_DATE)
  );

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const getDataForCurrency = useCallback(
    (currency: string) => {
      if (!dailyCosts) return [];
      return Object.entries(dailyCosts.daily_costs).map(([date, costs]) => ({
        time_stamp: date,
        value: costs[currency]
      }));
    },
    [dailyCosts]
  );

  const [chartDailyDataCurrencyFormatted, setChartDailyDataCurrencyFormatted] =
    useState<DailyCostsCurrencyFormatted>([]);

  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const tableCurrencies = ["eur", "chf"];

  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  const formatCurrency = (value: number | null, currency: string) => {
    if (value === null) return "N/A";
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency
    }).format(value);
  };

  const tooltipValueFormatter = (
    value: number | null,
    context: { currency: string }
  ): string => {
    if (value === null) return "N/A";

    const formattedValue = value.toFixed(2);
    // const formattedValue = new Intl.NumberFormat("en-US", {
    //   minimumFractionDigits: 2,
    //   maximumFractionDigits: 2
    // }).format(value);

    const upperCurrency = context.currency.toUpperCase();

    if (upperCurrency === "EUR") {
      return `${formattedValue} €`;
    } else if (upperCurrency === "CHF") {
      return `${formattedValue} CHF`;
    }
    return `${formattedValue} ${context.currency}`;
  };

  const regionsOptions = appConfig.availableRegions;
  const [showRegion, setShowRegion] = useState<string[]>(regionsOptions);
  const [hasError, setHasError] = useState(false);
  const handleChangeRegionFilter = useCallback((newValue: string[]) => {
    setShowRegion(newValue);
  }, []);

  // month chart
  const monthCosts = useSelector(monthBillingDataSelector);
  const monthCostsFormatted = useSelector(formattedMonthCostsSelector);

  const isMonthBillingDataLoading = useSelector(
    isMonthBillingDataLoadingSelector
  );

  const presentRegions = regionsOptions.filter((region) =>
    monthCostsFormatted?.some((monthData) => monthData[region] !== undefined)
  );

  const filteredRegionsOptions = regionsOptions.filter((option) =>
    presentRegions.includes(option)
  );

  const monthValueFormatter = (value: number | null) => `${value} €`;

  // shows data after click on month
  const [axisData, setAxisData] = useState<
    string | number | Date | undefined
  >(); // Jan-2024
  const previousAxisData = usePrevious(axisData);

  useMount(() => {
    dispatch(billingActions.getMonthChartBillingData.started({}));
  });

  useEffect(() => {
    if (currency && dailyCosts) {
      setChartDailyDataCurrencyFormatted(getDataForCurrency(currency));
    }
  }, [currency, dailyCosts, getDataForCurrency]);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        billingActions.getDailyChartBillingData.started({
          regions: showRegion.join(","),
          startDate,
          endDate
        })
      );
    }
  }, [dispatch, endDate, showRegion, startDate]);

  useEffect(() => {
    if (
      axisData &&
      axisData !== previousAxisData &&
      typeof axisData === "string"
    ) {
      const parsedDate = parse(axisData, "MMM-yyyy", new Date());
      const startOfMonthDate = max([startOfMonth(parsedDate), minDate]);
      let endOfMonthDate: Date;
      if (isSameMonth(parsedDate, currentDate)) {
        endOfMonthDate = subDays(currentDate, 1);
      } else {
        endOfMonthDate = endOfMonth(parsedDate);
      }

      setStartDate(formatDate(startOfMonthDate, DATE_FORMATS.ISO_DATE));
      setEndDate(formatDate(endOfMonthDate, DATE_FORMATS.ISO_DATE));
    }
  }, [axisData, currentDate, minDate, previousAxisData]);

  useUnmount(() => {
    dispatch(billingActions.clear());
  });

  const toolbarItems = (
    <s.Toolbar>
      <DatePicker
        label={"Start date"}
        onChange={handleDateChange(DATE_TYPES.START)}
        value={startDate ? new Date(startDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={minDate}
        maxDate={endDate ? new Date(endDate) : undefined}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      <DatePicker
        label={"End date"}
        onChange={handleDateChange(DATE_TYPES.END)}
        value={endDate ? new Date(endDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={startDate ? new Date(startDate) : minDate}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      {tableCurrencies.length > 0 && (
        <s.CurrencyFormControl>
          <InputLabel>Currency</InputLabel>
          <Select
            label={"Currency"}
            value={currency}
            onChange={handleCurrencyChange}
            size={"small"}
          >
            {tableCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </s.CurrencyFormControl>
      )}
      <s.RegionFormControl>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={regionsOptions}
          size={"small"}
          sx={{ marginRight: "10px", marginLeft: "10px" }}
          // getOptionLabel={(option) => option.title}
          value={showRegion}
          filterSelectedOptions
          // onChange={(event, newValue) => handleChangeRegionFilter(newValue)}
          onChange={(event, newValue) => {
            if (newValue.length > 0) {
              handleChangeRegionFilter(newValue);
              setHasError(false);
            } else {
              setHasError(true);
            }
          }}
          // renderInput={(params) => <TextField {...params} label="Region" />}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              error={hasError}
              helperText={
                hasError ? "At least least one region should be selected" : ""
              }
            />
          )}
        />
        {/* <InputLabel sx={{ marginLeft: "10px" }}>Region</InputLabel>
        <Select
          label={"Region"}
          // variant="standard"
          multiple
          value={showRegion}
          onChange={handleChangeRegionFilter}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          size={"small"}
          sx={{ marginRight: "10px", marginLeft: "10px" }}
        >
          {regionsOptions.map((region) => (
            <MenuItem key={region.value} value={region.value}>
              {region.label}
            </MenuItem>
          ))}
        </Select> */}
      </s.RegionFormControl>
    </s.Toolbar>
  );

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>

      <s.MetricsContainer
        variant={"outlined"}
        title="Monthly Costs by Region in EUR"
      >
        <Typography variant="h6" align="center" gutterBottom>
          Monthly Costs by Region in EUR (Last 12 Months)
        </Typography>
        {monthCostsFormatted &&
        // monthCostsFormatted.length > 0 &&
        !isMonthBillingDataLoading ? (
          <BarChart
            height={400}
            margin={{ left: 90, right: 80 }}
            dataset={monthCostsFormatted}
            // colors={["#00648c", "#0092cf", "#E40951", "#002060"]}
            colors={["#0092cf", "#65BCE2", "#F5145F", "#9D0638"]}
            onAxisClick={(event, d) => setAxisData(d?.axisValue)}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "month",
                valueFormatter: (code, context) =>
                  context.location === "tooltip"
                    ? `${monthCostsFormatted.find((d) => d.month === code)?.monthTotal}`
                    : String(code)
              }
            ]}
            yAxis={[
              {
                // label: "Cost (EUR)",
                valueFormatter: (value: number) => formatCurrency(value, "eur")
              }
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-30px, 0)"
              }
            }}
            // slotProps={{
            // axisLabel: {
            //   fontSize: 11,
            //   fontWeight: 450
            // },
            // axisTickLabel: {
            //   style: { whiteSpace: "pre-line" }
            // }
            // legend: {
            //   labelStyle: {
            //     fontSize: 13,
            //     fontWeight: 450
            //   }
            // }
            // }}
            series={filteredRegionsOptions.map((region) => ({
              dataKey: region,
              label: region,
              valueFormatter: monthValueFormatter
            }))}
          />
        ) : (
          <Loader text={"Loading data..."} />
        )}
      </s.MetricsContainer>

      <s.MetricsContainer variant={"outlined"}>
        <Typography variant="h6" align="center" gutterBottom>
          Daily Costs
        </Typography>
        {toolbarItems}
        <s.Description>
          Billing data is available for previous 12 months
        </s.Description>

        {chartDailyDataCurrencyFormatted && !isDailyBillingDataLoading ? (
          <LineChart
            height={400}
            margin={{ left: 90, right: 80 }}
            series={[
              {
                data: chartDailyDataCurrencyFormatted.map((item) => item.value),
                label: dailyCosts?.regions?.join(",  "),
                showMark: false,
                color: theme.palette.secondary.main,
                valueFormatter: (value) =>
                  tooltipValueFormatter(value, { currency })
              }
            ]}
            xAxis={[
              {
                data: chartDailyDataCurrencyFormatted.map(
                  (item) => item.time_stamp
                ),
                scaleType: "point"
                // valueFormatter: yearFormatter
              }
            ]}
            yAxis={[
              {
                // label: `Cost (${currency.toUpperCase()})`,
                valueFormatter: (value: number) =>
                  formatCurrency(value, currency)
              }
            ]}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-30px, 0)"
              }
            }}
          />
        ) : (
          <Loader text={"Loading data..."} />
        )}
      </s.MetricsContainer>
    </>
  );
};
