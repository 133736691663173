import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import { GetGpuInstancesParams, GetGpuInstancesResponse } from "./types";

export function* getGpuInstancesSaga(
  action: Action<GetGpuInstancesParams>
): SagaIterator<void> {
  try {
    const { regions } = action.payload;
    const response: AxiosResponse<GetGpuInstancesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/instances`,
      {
        params: {
          regions: regions ? regions : "",
          filter: "gpu"
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGpuInstances.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getGpuInstances.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get gpu virtual machines data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getGpuInstances.started, getGpuInstancesSaga)
  ]);
}
