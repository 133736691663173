import { formatDate } from "utils/formatDate";
import { RootReducer } from "../../reducers";
import { PublicIP } from "./types";
import { DATE_FORMATS } from "../../constants";

// export const publicIPsSelector = (state: RootReducer): PublicIP[] | null =>
//   state.networking.publicIPs
//     ? state.networking.publicIPs.list_public_ip.map((ip) => ({
//         ...ip,
//         id: ip.ip
//       }))
//     : null;

export const publicIPsSelector = (state: RootReducer): PublicIP[] | null =>
  state.networking.publicIPs?.list_public_ip?.map((ip) => ({
    ...ip,
    id: ip.ip
  })) ?? null;

export const publicIPsTotalSelector = (state: RootReducer): number | null =>
  state.networking.publicIPs ? state.networking.publicIPs.total_count : null;

export const publicIPsCashDataSelector = (state: RootReducer): string | null =>
  state.networking.publicIPs
    ? formatDate(
        new Date(state.networking.publicIPs.cached_at),
        DATE_FORMATS.DATETIME
      )
    : null;

export const publicIPsOffsetSelector = (
  state: RootReducer
): number | undefined =>
  state.networking.publicIPs ? state.networking.publicIPs.offset : undefined;

export const publicIPsLimitSelector = (
  state: RootReducer
): number | undefined =>
  state.networking.publicIPs ? state.networking.publicIPs.limit : undefined;

export const arePublicIPsLoadingSelector = (state: RootReducer): boolean =>
  state.networking.arePublicIPsLoading;

export const isPublicIPsCacheUpdatingSelector = (state: RootReducer): boolean =>
  state.networking.isCacheUpdating;
