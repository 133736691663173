import { FC } from "react";
import { PieChartMetricProps } from "./types";
import { useTheme } from "@mui/material";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import * as s from "./styles";

export const PieChartMetric: FC<PieChartMetricProps> = ({
  title,
  usedValue,
  freeValue,
  maxValue,
  unit,
  simpleMetrics,
  formatter
}: PieChartMetricProps) => {
  const theme = useTheme();

  const formattedUsed = formatter
    ? formatter(usedValue)
    : { value: usedValue, unit };
  const formattedFree = formatter
    ? formatter(freeValue)
    : { value: freeValue, unit };
  const formattedMax = formatter
    ? formatter(maxValue)
    : { value: maxValue, unit };

  const percentageUsed = Number(((usedValue / maxValue) * 100).toFixed(0));
  const percentageFree = Number(((freeValue / maxValue) * 100).toFixed(0));

  const data = [
    {
      id: 1,
      value: percentageUsed,
      label: `Used: ${formattedUsed.value} ${formattedUsed.unit}`,
      color: theme.palette.primary.main
    },
    {
      id: 2,
      value: percentageFree,
      label: `Free: ${formattedFree.value} ${formattedFree.unit}`,
      color: theme.palette.secondary.light
    }
  ];

  return (
    <s.MetricWrapper>
      <s.ChartContainer>
        <s.MetricTitleContainer>
          <s.MetricTitle>{title}</s.MetricTitle>
        </s.MetricTitleContainer>
        <s.MetricTitleContainer>
          <s.DetailsInfoColored>
            Total: {formattedMax.value} {formattedMax.unit}
          </s.DetailsInfoColored>
        </s.MetricTitleContainer>
        <PieChart
          series={[
            {
              data,
              innerRadius: 40,
              outerRadius: 80,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 360,
              cx: 90,
              cy: 90,
              arcLabel: (item) => `${item.value} %`,
              valueFormatter: (value) => `${value.value} %`,
              arcLabelMinAngle: 45,
              highlightScope: { faded: "global", highlighted: "item" },
              highlighted: {
                additionalRadius: 1
                // innerRadius: 41
                // color: theme.palette.secondary.dark
              }
            }
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontWeight: "bold"
            }
          }}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "right" },
              padding: 0,
              markGap: 6,
              itemGap: 8,
              itemMarkHeight: 14,
              labelStyle: {
                fontSize: 13,
                fontWeight: 450
              }
            }
          }}
          // tooltip={{
          //   trigger: "none",
          //   itemContent: (props) => <div {...props}>{unit}</div>
          // }}
          width={325}
          height={200}
        />
      </s.ChartContainer>
      {simpleMetrics.length > 0 && (
        <s.SimpleMetricsContainer>
          {simpleMetrics.map((metric) => (
            <s.SimpleMetricItem key={metric.label}>
              <s.SimpleMetricTitle>{metric.label}</s.SimpleMetricTitle>
              <s.SimpleMetricValue>
                {metric.value} {metric.unit}
              </s.SimpleMetricValue>
            </s.SimpleMetricItem>
          ))}
        </s.SimpleMetricsContainer>
      )}
    </s.MetricWrapper>
  );
};
