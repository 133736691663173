import actionCreatorFactory from "typescript-fsa";
import { GetGpuInstancesParams, GetGpuInstancesResponse } from "./types";

const actionCreator = actionCreatorFactory("INSTANCES");

export const getGpuInstances = actionCreator.async<
  GetGpuInstancesParams,
  GetGpuInstancesResponse,
  unknown
>("GET_GPU_INSTANCES");

export const clear = actionCreator<void>("CLEAR");
