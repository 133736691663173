import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetPublicIPsParams,
  GetPublicIPsResponse,
  UpdateCachePublicIPsResponse
} from "./types";

export function* getPublicIPsSaga(
  action: Action<GetPublicIPsParams>
): SagaIterator<void> {
  try {
    const { offset, limit, region, search, sort, order } = action.payload;
    const response: AxiosResponse<GetPublicIPsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/ips`,
      {
        params: {
          offset,
          limit,
          region,
          sort,
          order,
          search
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getPublicIPs.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getPublicIPs.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get public IPs data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateCachePublicIPsSaga(
  action: Action<void>
): SagaIterator<void> {
  try {
    // const queryParams = new URLSearchParams(location.search);
    // const region = queryParams.get("region");
    // const search = queryParams.get("search");
    // const sort = queryParams.get("sort");
    // const order = queryParams.get("order");
    // const offset = Number(queryParams.get("offset") || "0");
    // const limit = Number(queryParams.get("limit") || "0");

    // const { offset, limit, region, search, sort, order } = action.payload;
    const response: AxiosResponse<UpdateCachePublicIPsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/ips/force-cache-update`
    );

    yield put(
      actions.updateCachePublicIPs.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "Public IPs report for all regions requested. Expect it within a few minutes.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );

    // yield put(
    //   notificationsActions.showNotification({
    //     title: `Updated regions:`,
    //     text: `${response.data.updated_regions.join("\n")}`,
    //     type: NOTIFICATION_TYPES.INFO
    //   })
    // );

    // yield put(
    //   actions.getPublicIPs.started({
    //     region: region!,
    //     search: search || "",
    //     sort: sort || "",
    //     order: order || "",
    //     offset: offset,
    //     limit: limit
    //   })
    // );
  } catch (e) {
    yield put(
      actions.updateCachePublicIPs.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cached public IPs data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getPublicIPs.started, getPublicIPsSaga),
    takeEvery(actions.updateCachePublicIPs.started, updateCachePublicIPsSaga)
  ]);
}
