import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { PublicIPs } from "./types";

export type Reducer = {
  publicIPs: PublicIPs | null;
  arePublicIPsLoading: boolean;
  isCacheUpdating: boolean;
};

const initialState: Reducer = {
  publicIPs: null,
  arePublicIPsLoading: false,
  isCacheUpdating: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getPublicIPs.started,
    (state): Reducer => ({
      ...state,
      arePublicIPsLoading: true
    })
  )
  .case(
    actions.getPublicIPs.done,
    (state, payload): Reducer => ({
      ...state,
      publicIPs: payload.result,
      arePublicIPsLoading: false
    })
  )
  .case(
    actions.getPublicIPs.failed,
    (state): Reducer => ({
      ...state,
      arePublicIPsLoading: false
    })
  )

  .case(
    actions.updateCachePublicIPs.started,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: true
    })
  )
  .case(
    actions.updateCachePublicIPs.done,
    (state, payload): Reducer => ({
      ...state,
      // publicIPs: payload.result,
      isCacheUpdating: false
    })
  )
  .case(
    actions.updateCachePublicIPs.failed,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
