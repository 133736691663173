import { formatDate } from "utils/formatDate";
import { RootReducer } from "../../reducers";
import { DATE_FORMATS } from "../../constants";
import { Instance, TableGpuVirtualMachine } from "./types";

export const areGpuInstancesLoadingSelector = (state: RootReducer): boolean =>
  state.instances.areGpuInstancesLoading;

export const gpuInstancesSelector = (state: RootReducer): Instance[] | null =>
  state.instances.gpuInstances;

export const tableGpuVirtualMachinesSelector = (
  state: RootReducer
): TableGpuVirtualMachine[] | null =>
  state.instances.gpuInstances?.map((instance) => ({
    id: instance.id,
    name: instance.name,
    status: instance.status,
    ips: instance.ips,
    vCPUs: instance.flavor.vcpus || 0,
    memory: `${(instance.flavor?.ram || 0) / 1024} GiB`,
    flavor: instance.flavor.original_name,
    created: formatDate(new Date(instance.created), DATE_FORMATS.DATETIME),
    project_id: instance.project_id,
    project_name: instance.project_name,
    user_name: instance.user_name,
    user_id: instance.user_id,
    email: instance.email,
    region: instance.region,
    organization_id: instance.organization_id
  })) || null;
