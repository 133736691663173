import { Tooltip, useTheme, Zoom } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as monitoringActions from "modules/monitoring/actions";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig } from "../../appConfig";

import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import { CustomSelect } from "components/common/CustomSelect";
import { Loader } from "components/common/Loader";
import { usePrevious } from "hooks/usePrevious";
import {
  hypervisorsDataSelector,
  isHypervisorsDataLoadingSelector,
  isRegionResourceUtilizationDataLoadingSelector,
  regionResourceUtilizationDataSelector,
  resourceUtilizationDataSelector,
  tableHypervisorsDataSelector
} from "modules/monitoring/selectors";
import { formatMemorySize } from "utils/formatSize";
import { getSelectOption } from "utils/getSelectOption";
import { ADMIN_ROLES, ROUTES } from "../../constants";
import * as s from "./styles";
import { TABS } from "./types";
import { useNavigate } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { TableHypervisorsData } from "modules/monitoring/types";
import { TableColumn } from "components/common/Table/types";
import { Table } from "components/common/Table";
import { cancelMonitoringTokens } from "modules/monitoring/sagas";

const POLL_ID_PREFIX = "MONITORING";
const POLL_IDS = {
  REGION_RESOURCE_UTILIZATION: "region_resource_utilization",
  RESOURCE_UTILIZATION: "resource_utilization",
  HYPERVISORS_DATA: "hypervisors"
};

export const MonitoringOverview: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const theme = useTheme();

  const adminRole = useSelector(adminRoleSelector);
  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );
  const regionMonitoringData = useSelector(
    regionResourceUtilizationDataSelector
  );
  const isRegionResourceUtilizationDataLoading = useSelector(
    isRegionResourceUtilizationDataLoadingSelector
  );
  const monitoringData = useSelector(resourceUtilizationDataSelector);
  // const isMonitoringDataLoading = useSelector(isMonitoringDataLoadingSelector);

  const hypervisorsData = useSelector(hypervisorsDataSelector);
  const tableHypervisorsData = useSelector(tableHypervisorsDataSelector);
  const isHypervisorsDataLoading = useSelector(
    isHypervisorsDataLoadingSelector
  );

  const tableHypervisorsColumns: TableColumn<TableHypervisorsData>[] = [
    { key: "id", label: "Hostname", width: "15%" },
    { key: "running_vms", label: "VMs" },
    { key: "vcpus_used", label: "vCPUs used" },
    { key: "vcpus_total", label: "vCPUs total" },
    { key: "ram_used", label: "RAM used" },
    { key: "ram_total", label: "RAM total" },
    { key: "time_since_update", label: "Updated" },
    { key: "status", label: "Status", width: "9%", isCentered: false },
    { key: "state", label: "State", width: "80px", isCentered: true }
  ];

  const MONITORING_TAB_TITLES: {
    [key in TABS]: { title: string; disabled: boolean };
  } = useMemo(
    () => ({
      [TABS.GENERAL_OVERVIEW]: {
        title: "Region Overview",
        disabled: false
      },
      [TABS.RAM]: {
        title: "RAM",
        disabled: false
      },
      [TABS.CPU]: {
        title: "CPU",
        disabled: false
      },
      [TABS.DISK_SPACE]: {
        title: "Disk space",
        disabled: true
      }
    }),
    []
  );

  const activeTabIndexFromParam = Object.keys(MONITORING_TAB_TITLES).find(
    (key) =>
      MONITORING_TAB_TITLES[key] &&
      String(MONITORING_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.GENERAL_OVERVIEW) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const allRegionsAsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const [showOnly, setShowOnly] = useState<string>(
    allRegionsAsOptions[0].value
  );
  const previousShowOnly = usePrevious(showOnly);

  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
  }, []);

  const title = `Hypervisors Overview`;

  const breadcrumbs: Breadcrumb[] = [
    { text: "Hypervisors", url: ROUTES.UTILIZATION }
    // {
    //   text: project?.name || "",
    //   url: generatePath(ROUTES.PROJECT, {
    //     region: matchParams.region,
    //     projectId: matchParams.projectId
    //   })
    // }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`,
        action: monitoringActions.getRegionResourceUtilizationData.started({
          region: showOnly
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`,
        action: monitoringActions.getHypervisorsData.started({
          region: showOnly
        })
      })
    );
    // dispatch(
    //   pollingActions.startPolling({
    //     id: `${POLL_ID_PREFIX}/${POLL_IDS.RESOURCE_UTILIZATION}`,
    //     action: monitoringActions.getResourceUtilizationData.started({
    //       region: ""
    //     })
    //   })
    // );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(monitoringActions.clear());
  });

  useEffect(() => {
    if (
      isObserveRoleAllowed &&
      previousShowOnly !== undefined &&
      previousShowOnly !== showOnly
    ) {
      // Cancel the previous requests due to new parameters
      cancelMonitoringTokens["regionResourceUtilization"]?.cancel();
      cancelMonitoringTokens["regionResourceUtilization"] = null;
      cancelMonitoringTokens["hypervisors"]?.cancel();
      cancelMonitoringTokens["hypervisors"] = null;

      // stop polling
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`
        })
      );
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`
        })
      );

      // clear state - to add loaders if change some of the options
      dispatch(monitoringActions.clear());

      // This two dispatches is needed because request could take a lot of time and the next polling iteration with new parameters will be skipped.
      // dispatch(
      //   monitoringActions.getHypervisorsData.started({
      //     region: showOnly
      //   })
      // );
      // dispatch(
      //   monitoringActions.getRegionResourceUtilizationData.started({
      //     region: showOnly
      //   })
      // );

      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`,
          action: monitoringActions.getRegionResourceUtilizationData.started({
            region: showOnly
          })
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`,
          action: monitoringActions.getHypervisorsData.started({
            region: showOnly
          })
        })
      );
    }
  }, [dispatch, isObserveRoleAllowed, previousShowOnly, showOnly]);

  // useEffect(() => {
  //   switch (activeTabIndex) {
  //     case TABS.GENERAL_OVERVIEW:
  //       history({
  //         search: generateSearchString({
  //           tab: MONITORING_TAB_TITLES[TABS.GENERAL_OVERVIEW].title
  //         })
  //       });
  //       break;
  //     case TABS.RAM:
  //       history({
  //         search: generateSearchString({
  //           tab: MONITORING_TAB_TITLES[TABS.RAM].title
  //         })
  //       });
  //       break;
  //     case TABS.CPU:
  //       history({
  //         search: generateSearchString({
  //           tab: MONITORING_TAB_TITLES[TABS.CPU].title
  //         })
  //       });
  //       break;
  //     case TABS.DISK_SPACE:
  //       history({
  //         search: generateSearchString({
  //           tab: MONITORING_TAB_TITLES[TABS.DISK_SPACE].title
  //         })
  //       });
  //       break;
  //   }
  // }, [history, activeTabIndex, dispatch, MONITORING_TAB_TITLES]);

  interface SimpleMetric {
    label: string;
    value: number | string;
    unit: string;
  }

  type FormatterFunction = (value: number) => { value: number; unit: string };

  // the same, as if use PieChartMetric custom component
  const renderMetric = (
    title: string,
    usedValue: number,
    freeValue: number,
    maxValue: number,
    unit: string = "",
    simpleMetrics: SimpleMetric[] = [],
    formatter: FormatterFunction | null = null
  ) => {
    const formattedUsed = formatter
      ? formatter(usedValue)
      : { value: usedValue, unit };
    const formattedFree = formatter
      ? formatter(freeValue)
      : { value: freeValue, unit };
    const formattedMax = formatter
      ? formatter(maxValue)
      : { value: maxValue, unit };

    const percentageUsed = Number(((usedValue / maxValue) * 100).toFixed(0));
    const percentageFree = Number(((freeValue / maxValue) * 100).toFixed(0));

    const data = [
      {
        id: 1,
        value: percentageUsed,
        label: `Used: ${formattedUsed.value} ${formattedUsed.unit}`,
        color: theme.palette.primary.main
      },
      {
        id: 2,
        value: percentageFree,
        label: `Free: ${formattedFree.value} ${formattedFree.unit}`,
        color: theme.palette.secondary.light
      }
    ];

    return (
      <s.MetricWrapper>
        <s.ChartContainer>
          <s.MetricTitleContainer>
            <s.MetricTitle>{title}</s.MetricTitle>
          </s.MetricTitleContainer>
          <s.MetricTitleContainer>
            <s.DetailsInfoColored>
              Total: {formattedMax.value} {formattedMax.unit}
            </s.DetailsInfoColored>
          </s.MetricTitleContainer>
          <PieChart
            series={[
              {
                data,
                innerRadius: 40,
                outerRadius: 80,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 360,
                cx: 90,
                cy: 90,
                arcLabel: (item) => `${item.value} %`,
                valueFormatter: (value) => `${value.value} %`,
                arcLabelMinAngle: 45,
                highlightScope: { faded: "global", highlighted: "item" },
                highlighted: {
                  additionalRadius: 1
                  // innerRadius: 41
                  // color: theme.palette.secondary.dark
                }
              }
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold"
              }
            }}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "middle", horizontal: "right" },
                padding: 0,
                markGap: 6,
                itemGap: 8,
                itemMarkHeight: 14,
                labelStyle: {
                  fontSize: 13,
                  fontWeight: 450
                }
              }
            }}
            // tooltip={{
            //   trigger: "none",
            //   itemContent: (props) => <div {...props}>{unit}</div>
            // }}
            width={325}
            height={200}
          />
        </s.ChartContainer>
        {simpleMetrics.length > 0 && (
          <s.SimpleMetricsContainer>
            {simpleMetrics.map((metric) => (
              <s.SimpleMetricItem key={metric.label}>
                <s.SimpleMetricTitle>{metric.label}</s.SimpleMetricTitle>
                <s.SimpleMetricValue>
                  {metric.value} {metric.unit}
                </s.SimpleMetricValue>
              </s.SimpleMetricItem>
            ))}
          </s.SimpleMetricsContainer>
        )}
      </s.MetricWrapper>
    );
  };

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      // <s.MetricWrapper>
      <s.SimpleMetricsContainer>
        <Tooltip
          title={helperText}
          arrow
          placement={"top"}
          TransitionComponent={Zoom}
        >
          <s.SimpleMetricItem2>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem2>
        </Tooltip>
      </s.SimpleMetricsContainer>
      // </s.MetricWrapper>
    );
  };

  const tabContent = [
    <>
      <CustomSelect
        label={"Region"}
        options={allRegionsAsOptions}
        defaultValue={allRegionsAsOptions[0].value}
        onChange={handleChangeFilter}
        margin="30px 0 10px 0"
      />
      <s.MetricsContainer variant={"outlined"}>
        {!regionMonitoringData ||
        (showOnly !== previousShowOnly &&
          isRegionResourceUtilizationDataLoading) ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <h4 style={{ textAlign: "center" }}>
              {regionMonitoringData.region}
            </h4>
            <s.MetricRowContainer>
              <s.MetricContainer>
                {renderSimpleMetric(
                  "Hypervisors Count",
                  regionMonitoringData.statistics.count,
                  ""
                )}
                {renderSimpleMetric(
                  "Running VMs",
                  regionMonitoringData.statistics.running_vms,
                  "VMs"
                  // "The total number of running VMs on all hypervisors"
                )}
              </s.MetricContainer>
              <s.MetricContainer>
                {renderMetric(
                  "RAM Usage",
                  regionMonitoringData.statistics.memory_mb_used,
                  regionMonitoringData.statistics.free_ram_mb,
                  regionMonitoringData.statistics.memory_mb,
                  "MB",
                  [],
                  formatMemorySize
                )}
              </s.MetricContainer>
              <s.MetricContainer>
                {renderMetric(
                  "vCPU Usage",
                  regionMonitoringData.statistics.vcpus_used,
                  regionMonitoringData.statistics.vcpus -
                    regionMonitoringData.statistics.vcpus_used,
                  regionMonitoringData.statistics.vcpus,
                  "vCPUs",
                  [
                    {
                      label: "Physical Cores",
                      value: regionMonitoringData.statistics.cpus,
                      unit: ""
                    },
                    {
                      label: "vCPU",
                      value: regionMonitoringData.statistics.vcpus,
                      unit: ""
                    },
                    {
                      label: "Overallocation ratio",
                      value:
                        regionMonitoringData.statistics.vcpu_allocation_ratio,
                      unit: ""
                    }
                  ]
                )}
              </s.MetricContainer>
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer>
    </>,
    <>
      <s.MetricsContainer variant={"outlined"}>
        {!monitoringData ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <s.MetricRowContainer>
              {monitoringData.map((data, index) => (
                <s.MetricContainer key={index}>
                  {renderMetric(
                    data.region,
                    data.statistics.memory_mb_used,
                    data.statistics.free_ram_mb,
                    data.statistics.memory_mb,
                    "MB",
                    [],
                    formatMemorySize
                  )}
                </s.MetricContainer>
              ))}
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer>
    </>,
    <>
      <s.MetricsContainer variant={"outlined"}>
        {!monitoringData ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <s.MetricRowContainer>
              {monitoringData.map((data, index) => (
                <s.MetricContainer key={index}>
                  {renderMetric(
                    data.region,
                    data.statistics.vcpus_used,
                    data.statistics.vcpus - data.statistics.vcpus_used,
                    data.statistics.vcpus,
                    "vCPUs",
                    [
                      {
                        label: "Physical Cores",
                        value: data.statistics.cpus,
                        unit: ""
                      },
                      {
                        label: "vCPU",
                        value: data.statistics.vcpus,
                        unit: ""
                      },
                      {
                        label: "Overallocation ratio",
                        value: data.statistics.vcpu_allocation_ratio,
                        unit: ""
                      }
                    ]
                  )}
                </s.MetricContainer>
              ))}
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer>
    </>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <>
        <CustomSelect
          label={"Region"}
          options={allRegionsAsOptions}
          defaultValue={allRegionsAsOptions[0].value}
          onChange={handleChangeFilter}
          margin="20px 0 10px 0"
        />
        <s.MetricsContainer variant={"outlined"}>
          {!regionMonitoringData ? (
            <Loader text={"Loading data..."} />
          ) : (
            <>
              <h4 style={{ textAlign: "center", margin: "10px" }}>
                {regionMonitoringData.region}
              </h4>
              <s.MetricRowContainer>
                <s.MetricContainer>
                  {renderSimpleMetric(
                    "Hypervisors Count",
                    regionMonitoringData.statistics.count,
                    ""
                  )}
                  {renderSimpleMetric(
                    "Running VMs",
                    regionMonitoringData.statistics.running_vms,
                    "VMs"
                    // "The total number of running VMs on all hypervisors"
                  )}
                </s.MetricContainer>
                <s.MetricContainer>
                  {renderMetric(
                    "RAM Usage",
                    regionMonitoringData.statistics.memory_mb_used,
                    regionMonitoringData.statistics.free_ram_mb,
                    regionMonitoringData.statistics.memory_mb,
                    "MB",
                    [],
                    formatMemorySize
                  )}
                </s.MetricContainer>
                <s.MetricContainer>
                  {renderMetric(
                    "vCPU Usage",
                    regionMonitoringData.statistics.vcpus_used,
                    regionMonitoringData.statistics.vcpus -
                      regionMonitoringData.statistics.vcpus_used,
                    regionMonitoringData.statistics.vcpus,
                    "vCPUs",
                    [
                      {
                        label: "Physical Cores",
                        value: regionMonitoringData.statistics.cpus,
                        unit: ""
                      },
                      {
                        label: "vCPU",
                        value: regionMonitoringData.statistics.vcpus,
                        unit: ""
                      },
                      {
                        label: "Overallocation ratio",
                        value:
                          regionMonitoringData.statistics.vcpu_allocation_ratio,
                        unit: ""
                      }
                    ]
                  )}
                </s.MetricContainer>
              </s.MetricRowContainer>
            </>
          )}
        </s.MetricsContainer>
        <Table<TableHypervisorsData>
          // isSearchEnabled={true}
          isSortingEnabled={true}
          rows={tableHypervisorsData || []}
          columns={tableHypervisorsColumns}
          isLoading={!tableHypervisorsData}
          itemWithIcons={[
            {
              withText: false,
              column: "state",
              getIcon: (row) => {
                if (row["state"] === "up")
                  return (
                    <s.TypographyColoredSuccess>
                      {row["state"]}
                    </s.TypographyColoredSuccess>
                  );
                if (row["state"] === "down")
                  return (
                    <s.TypographyColoredDanger>
                      {row["state"]}
                    </s.TypographyColoredDanger>
                  );
                return (
                  <s.CustomTableCellText>{row["state"]}</s.CustomTableCellText>
                ); // or default icon
              },
              tooltipText: (row) => {
                return "";
              }
            },
            {
              withText: false,
              column: "status",
              getIcon: (row) => {
                if (row["status"] === "disabled")
                  return (
                    <s.TypographyColoredWarning>
                      {row["status"]}
                    </s.TypographyColoredWarning>
                  );
                return (
                  <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
                ); // or default icon
              },
              tooltipText: (row) => {
                return `${String(row.service_disabled_reason)}`;
              }
            }
          ]}
        />
      </>
      {/* <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(MONITORING_TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]} */}
    </>
  );
};
