import { Tab, Tabs } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as instancesActions from "modules/instances/actions";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_REGION,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { TableGpuVirtualMachine } from "modules/instances/types";
import {
  areGpuInstancesLoadingSelector,
  gpuInstancesSelector,
  tableGpuVirtualMachinesSelector
} from "modules/instances/selectors";
import { TABS } from "./types";

const POLL_ID_PREFIX = "GpuInstances";

const POLL_IDS = {
  gpuInstances: "GpuInstances"
};

const title = "Virtual Machines";

const breadcrumbs: Breadcrumb[] = [
  { text: "Instances", url: ROUTES.VIRTUAL_MACHINES }
];

const tableColumns: TableColumn<TableGpuVirtualMachine>[] = [
  { key: "name", label: "Name" },
  { key: "flavor", label: "Flavor" },
  { key: "status", label: "Status" },
  { key: "ips", label: "IP" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const VirtualMachines: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );

  const gpuInstances = useSelector(gpuInstancesSelector);
  const gpuInstancesTable = useSelector(tableGpuVirtualMachinesSelector);

  const areGpuInstancesLoading = useSelector(areGpuInstancesLoadingSelector);

  const queryParams = new URLSearchParams(location.search);

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);
  const prevDebounceSearch = usePrevious(debouncedSearch);
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // setPage(0);
  }, []);

  const VMS_TAB_TITLES: {
    [key in TABS]: { title: string; disabled: boolean };
  } = useMemo(
    () => ({
      [TABS.GPU_INSTANCES]: {
        title: "GPU Instances",
        disabled: false
      }
    }),
    []
  );

  const activeTabIndexFromParam = Object.keys(VMS_TAB_TITLES).find(
    (key) =>
      VMS_TAB_TITLES[key] &&
      String(VMS_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.GPU_INSTANCES) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const [showOnly, setShowOnly] = useState<string>(
    tableRegionsOptions[0].value
  );
  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
    // setPage(0);
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
        action: instancesActions.getGpuInstances.started({
          regions: showOnly,
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
  });

  useEffect(() => {
    if (isObserveRoleAllowed) {
      history({
        search: generateSearchString({
          regions: String(showOnly),
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      });
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
          action: instancesActions.getGpuInstances.started({
            regions: showOnly,
            ...(debouncedSearch ? { search: debouncedSearch } : {})
          })
        })
      );
    }
  }, [
    prevDebounceSearch,
    debouncedSearch,
    dispatch,
    isObserveRoleAllowed,
    showOnly,
    history
  ]);

  useEffect(() => {
    Object.values([POLL_IDS.gpuInstances]).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    switch (activeTabIndex) {
      case TABS.GPU_INSTANCES:
        history({
          search: generateSearchString({
            tab: VMS_TAB_TITLES[TABS.GPU_INSTANCES].title
          })
        });
        dispatch(instancesActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
            action: instancesActions.getGpuInstances.started({
              regions: showOnly,
              ...(debouncedSearch ? { search: debouncedSearch } : {})
            })
          })
        );
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    VMS_TAB_TITLES,
    showOnly,
    debouncedSearch
  ]);

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: gpuInstancesTable?.find((vm) => vm.id == id)?.user_id
      }),
    [gpuInstancesTable]
  );

  const tabContent = [
    <>
      <Table<TableGpuVirtualMachine>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={gpuInstancesTable || []}
        columns={tableColumns}
        isLoading={!gpuInstancesTable}
        itemLink={{
          column: "email",
          getURL: generateTableItemURL
        }}
        // isLoading={arePublicIPsLoading || isPublicIPsCacheUpdating}
        customItem={
          <s.CustomDiv>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              onChange={handleChangeFilter}
              // margin={"0 0 0 auto"}
            />
          </s.CustomDiv>
        }
      />
    </>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(VMS_TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
