import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isAdminAddingToOrganizationSelector,
  isAdminRemovingFromOrganizationSelector,
  isGroupCreatingSelector,
  isGroupDeletingSelector,
  isGroupUpdatingSelector,
  isOrganizationDeletingSelector,
  isOrganizationOwnerChangingSelector,
  isOrganizationUpdatingSelector,
  isUserAddingToGroupSelector,
  isUserRemovingFromGroupSelector,
  organizationGroupsSelector,
  organizationProjectsSelector,
  organizationSelector,
  organizationUsersSelector
} from "modules/enterprises/selectors";
import {
  Group,
  OrganizationProject,
  OrganizationUser,
  ROLES
} from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { getProjectRegion } from "utils/getProjectRegion";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ADMIN_ROLES,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  PROJECT_NAME_LENGTH,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "ORGANIZATION";
const POLL_IDS = {
  organization: "ORGANIZATION",
  organizationGroups: "ORGANIZATION_GROUPS",
  organizationProjects: "ORGANIZATION_PROJECTS",
  organizationUsers: "ORGANIZATION_USERS"
};

const organizationUsersColumns: TableColumn<OrganizationUser>[] = [
  { key: "email", label: "Email" },
  { key: "full_name", label: "Full Name" },
  { key: "id", label: "ID" },
  { key: "role", label: "Role" }
];

const organizationProjectsTableColumns: TableColumn<OrganizationProject>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  {
    key: "region",
    label: "Region",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  }
];

const organizationGroupsTableColumns: TableColumn<Group>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" }
];

const ORGANIZATION_TAB_TITLES: { [key in TABS]: string } = {
  [TABS.ADMINISTRATORS]: "Administrators",
  [TABS.PROJECTS]: "Projects"
  // [TABS.GROUPS]: "Groups"
};

export const Organization: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );
  const matchParams = useParams<{
    organizationId: string;
  }>();

  const volumeLimits = {
    absolute: {
      maxTotalVolumes: 10,
      maxTotalSnapshots: 10,
      maxTotalVolumeGigabytes: 1000,
      totalVolumesUsed: 6,
      totalGigabytesUsed: 400,
      totalSnapshotsUsed: 2
    }
  };

  const instanceLimits = {
    absolute: {
      maxTotalCores: 10,
      maxTotalInstances: 10,
      maxTotalRAMSize: 10240,
      totalCoresUsed: 4,
      totalInstancesUsed: 2,
      totalRAMUsed: 8192
    }
  };

  const networkLimits = {
    network_limits: 100,
    network_used: 0,
    security_groups_limits: 10,
    security_groups_used: 2,
    security_groups_rules_limits: 100,
    security_groups_rules_used: 6
  };

  // const organization = {
  //   id: "f33672b8-40dc-40ac-8701-e63deedf80a0",
  //   name: "Test-Orga",
  //   project_number_limit: 6,
  //   owner: "5b1aef58-b5d2-4c38-a249-abf9d103a357"
  // };

  // const organizationProjects = [
  //   {
  //     id: "6a75e75b8b5d470a96a7e4cfdbb55ec9",
  //     name: "Test-4",
  //     region: "Upper-Austria",
  //     org_id: "6a75e75b8b5d470a96a7e4cfdbb55ec9",
  //     org_name: "Test_Org"
  //   },
  //   {
  //     id: "5205d6aa707041ecbf77ba7d8e4827ab",
  //     name: "Test-Project",
  //     region: "Upper-Austria",
  //     org_id: "6a75e75b8b5d470a96a7e4cfdbb55ec9",
  //     org_name: "Test_Org"
  //   },
  //   {
  //     id: "d2d290014f4d4567a73f225fa00388d6",
  //     name: "Test-Vienna",
  //     region: "Vienna",
  //     org_id: "6a75e75b8b5d470a96a7e4cfdbb55ec9",
  //     org_name: "Test_Org"
  //   }
  // ];
  const organization = useSelector(organizationSelector);
  const isOrganizationUpdating = useSelector(isOrganizationUpdatingSelector);
  const isOrganizationDeleting = useSelector(isOrganizationDeletingSelector);
  const isOrganizationsOwnerChanging = useSelector(
    isOrganizationOwnerChangingSelector
  );
  const isOrganizationOperationInProgress =
    isOrganizationUpdating ||
    isOrganizationDeleting ||
    isOrganizationsOwnerChanging;
  const previousIsOrganizationOperationInProgress = usePrevious(
    isOrganizationOperationInProgress
  );

  const organizationUsers = useSelector(organizationUsersSelector);
  const isAdminAddingToOrganization = useSelector(
    isAdminAddingToOrganizationSelector
  );
  const isAdminRemovingFromOrganization = useSelector(
    isAdminRemovingFromOrganizationSelector
  );
  const isOrganizationUsersOperationInProgress =
    isAdminAddingToOrganization ||
    isAdminRemovingFromOrganization ||
    isOrganizationsOwnerChanging;
  const previousIsOrganizationUsersOperationInProgress = usePrevious(
    isOrganizationUsersOperationInProgress
  );

  const organizationProjects = useSelector(organizationProjectsSelector);

  const organizationGroups = useSelector(organizationGroupsSelector);
  // const groupUsers = useSelector(groupUsersSelector);
  const isGroupCreating = useSelector(isGroupCreatingSelector);
  const isGroupUpdating = useSelector(isGroupUpdatingSelector);
  const isGroupDeleting = useSelector(isGroupDeletingSelector);
  const isUserAddingToGroup = useSelector(isUserAddingToGroupSelector);
  const isUserRemovingFromGroup = useSelector(isUserRemovingFromGroupSelector);
  const isOrganizationGroupsOperationInProgress =
    isGroupCreating ||
    isGroupUpdating ||
    isUserAddingToGroup ||
    isUserRemovingFromGroup ||
    isGroupDeleting;
  const previousIsOrganizationGroupsOperationInProgress = usePrevious(
    isOrganizationGroupsOperationInProgress
  );

  const title = organization ? organization.name : undefined;

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  const allRegionsAsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.ADMINISTRATORS]: {
          title: "Administrators",
          disabled: !isUserManagementAllowed
        },
        [TABS.PROJECTS]: {
          title: "Projects",
          disabled: !isUserManagementAllowed
        }
        // [TABS.GROUPS]: {
        //   title: "Groups",
        //   disabled: !isUserManagementAllowed
        // }
      }),
      [isUserManagementAllowed]
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      ORGANIZATION_TAB_TITLES[key] &&
      String(ORGANIZATION_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.ADMINISTRATORS) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentProjectName = organizationProjects?.find(
    (project) => project.id === currentItemId
  )?.name;
  const currentOrganizationUserEmail = organizationUsers?.find(
    (user) => user.id === currentItemId
  )?.email;
  const currentGroupName = organizationGroups?.find(
    (group) => group.id === currentItemId
  )?.name;

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT_PROJECT, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const headerMenuItems = [
    {
      title: "Edit",
      icon: <EditIcon />,
      onClick: () => handleDialogOpen(DIALOG_TYPES.EDIT_ORGANIZATION)
    },
    {
      title: "Delete Organization",
      icon: <DeleteIcon />,
      onClick: () => handleDialogOpen(DIALOG_TYPES.DELETE_ORGANIZATION)
    }
  ];

  const tableUsersActions: TableRowActionsMenuItem<OrganizationUser>[] = [
    {
      label: "Remove Administrator",
      isDisabled: (user) => user.role === ROLES.OWNER,
      handler: (id) => handleDialogOpen(DIALOG_TYPES.REMOVE_ADMIN, id)
    },
    {
      label: "Change Ownership",
      isDisabled: (user) => user.role === ROLES.OWNER,
      handler: (id) => handleDialogOpen(DIALOG_TYPES.CHANGE_OWNERSHIP, id)
    }
  ];

  // const tableProjectsActions: TableRowActionsMenuItem<OrganizationProject>[] = [
  //   {
  //     label: "Set Quotas",
  //     handler: (id) => handleDialogOpen(DIALOG_TYPES.SET_PROJECT_QUOTAS, id)
  //   },
  //   {
  //     label: "Edit",
  //     handler: (id) => handleDialogOpen(DIALOG_TYPES.EDIT_PROJECT, id)
  //   },
  //   {
  //     label: "Delete",
  //     handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_PROJECT, id)
  //   }
  // ];

  const tableGroupsActions: TableRowActionsMenuItem<Group>[] = [
    {
      label: "Edit",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.EDIT_GROUP, id)
    },
    {
      label: "Add User",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.ADD_USER_TO_GROUP, id)
    },
    {
      label: "Remove User",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.REMOVE_USER_FROM_GROUP, id)
    },
    {
      label: "Delete",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_GROUP, id)
    }
  ];

  const generateProjectsTableItemURL = useCallback(
    (id: string) => {
      if (organizationProjects) {
        return generatePath(ROUTES.PROJECT, {
          region: getProjectRegion(organizationProjects, id),
          projectId: id
        });
      }
    },
    [organizationProjects]
  );

  const generateGroupsTableItemURL = useCallback(
    (id: string) => generatePath(ROUTES.GROUP, { projectId: id }),
    []
  );

  const handleConfirmEditOrganizationInfo = useCallback(
    (data: { name: string; project_number_limit: string }) => {
      if (organization) {
        dispatch(
          enterprisesActions.updateOrganization.started({
            id: matchParams.organizationId!,
            data: {
              name: data.name,
              project_number_limit: Number(data.project_number_limit)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, matchParams.organizationId, organization]
  );

  const handleConfirmDeleteOrganization = useCallback(() => {
    if (organization) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: matchParams.organizationId!
        })
      );
    }
    handleCloseDialog();
  }, [organization, handleCloseDialog, dispatch, matchParams.organizationId]);

  const handleConfirmAddAdminToOrganization = useCallback(
    (data: { user_email: string }) => {
      if (organization) {
        dispatch(
          enterprisesActions.addAdminToOrganization.started({
            "org-id": matchParams.organizationId!,
            "user-email": data.user_email.toLowerCase()
          })
        );
      }
      handleCloseDialog();
    },
    [organization, handleCloseDialog, dispatch, matchParams.organizationId]
  );

  const handleConfirmChangeOrganizationOwner = useCallback(() => {
    if (selectedItemId && currentOrganizationUserEmail) {
      dispatch(
        enterprisesActions.changeOrganizationOwner.started({
          "org-id": matchParams.organizationId!,
          "user-email": currentOrganizationUserEmail.toLowerCase()
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    currentOrganizationUserEmail,
    handleCloseDialog,
    dispatch,
    matchParams.organizationId
  ]);

  const handleConfirmRemoveAdminFromOrganization = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.removeAdminFromOrganization.started({
          "org-id": matchParams.organizationId!,
          "user-id": selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, handleCloseDialog, dispatch, matchParams.organizationId]);

  const handleConfirmCreateProject = useCallback(
    (data: { project_name: string; region: SelectOption }) => {
      dispatch(
        projectsActions.createProject.started({
          name: data.project_name,
          organizationId: matchParams.organizationId!,
          region: data.region.value
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog, matchParams.organizationId]
  );

  const handleConfirmEditProject = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        // console.log(data);
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog]
  );

  const handleConfirmSetQuotasProject = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        // console.log(data);
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeleteProject = useCallback(() => {
    if (selectedItemId) {
      // console.log(selectedItemId);
    }
    handleCloseDialog();
  }, [selectedItemId, handleCloseDialog]);

  const handleConfirmCreateGroup = useCallback(
    (data: { name: string }) => {
      if (organization) {
        dispatch(
          enterprisesActions.createGroup.started({
            data: {
              name: data.name
            },
            organizationId: matchParams.organizationId!
          })
        );
        handleCloseDialog();
      }
    },
    [dispatch, handleCloseDialog, matchParams.organizationId, organization]
  );

  const handleConfirmEditGroup = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.updateGroup.started({
            id: selectedItemId,
            organizationId: matchParams.organizationId!,
            data
          })
        );
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog, dispatch, matchParams.organizationId]
  );

  const handleConfirmAddUserToGroup = useCallback(
    (data: { user_email: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.addUserToGroup.started({
            organizationId: matchParams.organizationId!,
            groupId: selectedItemId,
            userEmail: data.user_email
          })
        );
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog, dispatch, matchParams.organizationId]
  );

  const handleConfirmRemoveUserFromGroup = useCallback(
    (data: { user_id: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.removeUserFromGroup.started({
            userId: data.user_id,
            organizationId: matchParams.organizationId!,
            groupId: selectedItemId
          })
        );
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog, dispatch, matchParams.organizationId]
  );

  const handleConfirmDeleteGroup = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteGroup.started({
          organizationId: matchParams.organizationId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [selectedItemId, handleCloseDialog, dispatch, matchParams.organizationId]);

  const tabContent = [
    <>
      <Table<OrganizationUser>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!organizationUsers}
        key={"organizationUsersTable"}
        rows={organizationUsers || []}
        columns={organizationUsersColumns}
        actions={tableUsersActions}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.ADD_ADMIN)}
            variant={"contained"}
            disabled={!organizationUsers}
          >
            Add Administrator
          </Button>
        }
      />
    </>,
    <>
      <Table<OrganizationProject>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!organizationProjects}
        // actions={tableProjectsActions}
        key={"organizationProjectsTable"}
        rows={organizationProjects || []}
        columns={organizationProjectsTableColumns}
        itemLink={{
          column: "name",
          getURL: generateProjectsTableItemURL
        }}
        toolbarItems={
          <span>
            <Button
              onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_PROJECT)}
              variant={"contained"}
              disabled={!organizationProjects}
            >
              Create project
            </Button>
          </span>
        }
      />
    </>,
    <>
      <Table<Group>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!organizationGroups}
        key={"organizationGroupsTable"}
        rows={organizationGroups || []}
        itemLink={{
          column: "name",
          getURL: generateGroupsTableItemURL
        }}
        columns={organizationGroupsTableColumns}
        actions={tableGroupsActions}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_GROUP)}
            variant={"contained"}
            disabled={!organizationGroups}
          >
            Create group
          </Button>
        }
      />
    </>
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT_ORGANIZATION]: {
      onConfirm: handleConfirmEditOrganizationInfo,
      title: "Edit current organization",
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "name",
          defaultValue: organization?.name || "",
          rules: string().required()
        },

        {
          name: "project_number_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Projects number limit",
          defaultValue: organization?.project_number_limit || 0,
          rules: number().integer()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ORGANIZATION]: {
      onConfirm: handleConfirmDeleteOrganization,
      title: `Are you sure you want to delete current organization?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ADD_ADMIN]: {
      onConfirm: handleConfirmAddAdminToOrganization,
      title: `Add Administrator`,
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New Administrator E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `✍ Only already registered users can be added`
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_ADMIN]: {
      onConfirm: handleConfirmRemoveAdminFromOrganization,
      title: `Are you sure you want to remove ${
        currentOrganizationUserEmail ?? ""
      } from organization's admins?`,
      confirmButtonLabel: "Remove"
    },
    [DIALOG_TYPES.CHANGE_OWNERSHIP]: {
      onConfirm: handleConfirmChangeOrganizationOwner,
      title: `Are you sure you want to transfer current organization ownership to the ${
        currentOrganizationUserEmail ?? ""
      }`,
      confirmButtonLabel: "Transfer"
    },
    [DIALOG_TYPES.CREATE_PROJECT]: {
      onConfirm: handleConfirmCreateProject,
      title: "Create project",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "project_name",
          type: FIELD_TYPES.TEXT,
          label: "Project Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        },
        {
          name: "region",
          type: FIELD_TYPES.SELECT,
          label: "Region",
          options: allRegionsAsOptions,
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.EDIT_PROJECT]: {
      onConfirm: handleConfirmEditProject,
      title: "Edit project",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            organizationProjects?.find(
              (project) => project.id === selectedItemId
            )?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        }
      ]
    },
    [DIALOG_TYPES.SET_PROJECT_QUOTAS]: {
      onConfirm: handleConfirmSetQuotasProject,
      title: `Set quotas for "${currentProjectName ?? "selected"}" project?`,
      fields: [
        {
          name: "vm_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines limit",
          defaultValue: instanceLimits.absolute.maxTotalInstances,
          min: 1,
          suffix: " vm ",
          rules: number().integer().required()
        },
        {
          name: "vm_total_vCPUs",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total vCPUs",
          defaultValue: instanceLimits.absolute.maxTotalCores,
          min: 1,
          suffix: "vCPU",
          rules: number().integer().required()
        },
        {
          name: "vm_total_memory",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total memory",
          defaultValue: instanceLimits.absolute.maxTotalRAMSize / 1024,
          min: 1,
          suffix: "GiB ",
          rules: number().integer().required()
        },
        {
          name: "volumes_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volumes limit",
          defaultValue: volumeLimits.absolute.maxTotalVolumes,
          min: 1,
          suffix: "vol",
          rules: number().integer().required()
        },
        {
          name: "volume_snapshots_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume snapshots limit",
          defaultValue: volumeLimits.absolute.maxTotalSnapshots,
          min: 1,
          suffix: "snap",
          rules: number().integer().required()
        },
        {
          name: "volume_storage_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume storage limit",
          defaultValue: volumeLimits.absolute.maxTotalVolumeGigabytes,
          min: 1,
          suffix: "GiB",
          rules: number().integer().required()
        },
        {
          name: "networks_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Networks limit",
          defaultValue: networkLimits.network_limits,
          min: 1,
          suffix: "net",
          rules: number().integer().required()
        },
        {
          name: "firewalls_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewalls limit",
          defaultValue: networkLimits.security_groups_limits,
          min: 1,
          suffix: "fw",
          rules: number().integer().required()
        },
        {
          name: "firewall_rules_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewall rules limit",
          defaultValue: networkLimits.security_groups_rules_limits,
          min: 1,
          suffix: "fw_r",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quotas"
    },
    [DIALOG_TYPES.DELETE_PROJECT]: {
      onConfirm: handleConfirmDeleteProject,
      title: `Are you sure you want to delete "${
        currentProjectName ?? "selected"
      }" project?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_GROUP]: {
      onConfirm: handleConfirmCreateGroup,
      title: "Create group",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.EDIT_GROUP]: {
      onConfirm: handleConfirmEditGroup,
      title: "Edit group",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            organizationGroups?.find((group) => group.id === selectedItemId)
              ?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_GROUP]: {
      onConfirm: handleConfirmDeleteGroup,
      title: `Are you sure you want to delete "${
        currentGroupName ?? "selected"
      }" group?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ADD_USER_TO_GROUP]: {
      onConfirm: handleConfirmAddUserToGroup,
      title: `Add User to the Group`,
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "user_email",
          type: FIELD_TYPES.TEXT,
          label: "New User E-mail",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        },
        {
          name: "notes",
          type: FIELD_TYPES.NOTES,
          label: `✍ Only already registered users can be added`
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_USER_FROM_GROUP]: {
      onConfirm: handleConfirmRemoveUserFromGroup,
      title: `Remove User from the Group`,
      confirmButtonLabel: "Remove",
      fields: [
        {
          name: "user_id",
          type: FIELD_TYPES.TEXT,
          label: "User ID",
          rules: string()
        }
      ]
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organization}`,
        action: enterprisesActions.getOrganization.started({
          id: matchParams.organizationId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationUsers}`,
        action: enterprisesActions.getOrganizationUsers.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      Object.values([
        POLL_IDS.organizationGroups,
        POLL_IDS.organizationUsers,
        POLL_IDS.organizationProjects
      ]).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });
      let searchParams = {};
      switch (activeTabIndex) {
        case TABS.ADMINISTRATORS:
          searchParams = {
            tab: TAB_TITLES[TABS.ADMINISTRATORS].title
          };
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationUsers}`,
              action: enterprisesActions.getOrganizationUsers.started({
                organizationId: matchParams.organizationId!
              })
            })
          );
          break;

        case TABS.PROJECTS:
          searchParams = {
            tab: TAB_TITLES[TABS.PROJECTS].title
          };
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationProjects}`,
              action: enterprisesActions.getOrganizationProjects.started({
                organizationId: matchParams.organizationId!
              })
            })
          );
          break;

        // case TABS.GROUPS:
        //   searchParams = {
        //     tab: TAB_TITLES[TABS.GROUPS].title
        //   };
        //   dispatch(
        //     pollingActions.startPolling({
        //       id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationGroups}`,
        //       action: enterprisesActions.getOrganizationGroups.started({
        //         owner: organization?.owner || "",
        //         organizationId: matchParams.organizationId
        //       })
        //     })
        //   );
        //   break;
        default:
          break;
      }
      history({ search: generateSearchString(searchParams) });
    }
  }, [
    dispatch,
    history,
    activeTabIndex,
    TAB_TITLES,
    previousActiveTabIndex,
    matchParams.organizationId,
    organization?.owner
  ]);

  useEffect(() => {
    if (
      previousIsOrganizationUsersOperationInProgress &&
      !isOrganizationUsersOperationInProgress
    ) {
      dispatch(
        enterprisesActions.getOrganizationUsers.started({
          organizationId: matchParams.organizationId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.organizationId,
    previousIsOrganizationUsersOperationInProgress,
    isOrganizationUsersOperationInProgress
  ]);

  useEffect(() => {
    if (
      previousIsOrganizationGroupsOperationInProgress &&
      !isOrganizationGroupsOperationInProgress
    ) {
      dispatch(
        enterprisesActions.getOrganizationGroups.started({
          organizationId: matchParams.organizationId!,
          owner: organization?.owner || ""
        })
      );
    }
  }, [
    dispatch,
    matchParams.organizationId,
    previousIsOrganizationUsersOperationInProgress,
    isOrganizationUsersOperationInProgress,
    previousIsOrganizationGroupsOperationInProgress,
    isOrganizationGroupsOperationInProgress,
    organization?.owner
  ]);

  useEffect(() => {
    if (
      previousIsOrganizationOperationInProgress &&
      !isOrganizationOperationInProgress
    ) {
      if (!organization) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          enterprisesActions.getOrganization.started({
            id: matchParams.organizationId!
          })
        );
      }
    }
  }, [
    history,
    dispatch,
    organization,
    matchParams.organizationId,
    previousIsOrganizationOperationInProgress,
    isOrganizationOperationInProgress
  ]);

  return (
    <>
      <>
        <Head title={title} />
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <s.SummaryContainer>
          <s.Title variant={"h4"} component={"h2"}>
            Organization: {title}
          </s.Title>
          {isUserManagementAllowed ? (
            <s.ActionsContainer>
              {headerMenuItems.map((menuItem, index) => (
                <Tooltip title={menuItem.title} arrow key={index}>
                  <IconButton
                    onClick={() => menuItem.onClick()}
                    color="inherit"
                  >
                    {menuItem.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </s.ActionsContainer>
          ) : (
            ""
          )}
        </s.SummaryContainer>
        {organization ? (
          <>
            <s.SummaryRowInfo>
              <s.DetailsTitleMain>ID: </s.DetailsTitleMain>
              <s.DetailsInfoMainColored>
                {organization.id}
              </s.DetailsInfoMainColored>
            </s.SummaryRowInfo>
            <s.SummaryRow>
              <s.DetailsTitleMain>Owner: </s.DetailsTitleMain>
              <s.DetailsInfoMainColored>
                {organization.owner}
              </s.DetailsInfoMainColored>
            </s.SummaryRow>
            <s.SummaryRow>
              <s.DetailsTitleMain>Projects Quotas: </s.DetailsTitleMain>
              <s.Tag
                key={organization.project_number_limit}
                label={organization.project_number_limit}
              />
            </s.SummaryRow>
          </>
        ) : (
          <Loader text={"Loading organizations information..."} />
        )}
      </>
      <>
        <s.TabsContainer>
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tab) => (
              <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
            ))}
          </Tabs>
        </s.TabsContainer>
        {tabContent[activeTabIndex]}
        <FormDialog
          isOpened={dialog.isOpened}
          onCancel={handleCloseDialog}
          fields={dialogProps[dialog.type].fields}
          onConfirm={dialogProps[dialog.type].onConfirm}
          title={dialogProps[dialog.type].title}
          confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
        />
      </>
    </>
  );
};
